*,
*::before,
*::after { box-sizing: border-box }

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-family: 'Friction Mono', monospace;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $black;
  color: $white;

  display: flex;
  flex-direction: column;
  min-height: 100%;

  &.is-loading::before, &.is-loading::after { opacity: 1 }
  &::before, &::after {
    @include easing(300ms, opacity);
    @include progress-loader(1px, 75vw, $white);
    top: 0;
    position: fixed;
    z-index: 3;
    opacity: 0;
  }

  &[no-scroll] {
    height: 100%;
    overflow: hidden;
  }
}

* {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: normal;
}

::selection {
  background: $black;
  color: $white;
}

a {
  @include underline;
  color: inherit;
  line-height: 1;
}

figure {
  @include loader;
  font-size: 0;
}

sup {
  font-size: 1rem;
}

@keyframes lozad--fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

[data-lozad] {
  opacity: 0;
}

[data-lozad][data-loaded="true"] {
  @if $debug { @include checkerboard(24px); }
  @else { background-color: $black; }

  animation: lozad--fade-in 1s linear forwards;
}

[data-contrast="light"] {
  color: $black !important;
}

[data-contrast="dark"] {
  color: $white !important;
}

main {
  margin-top: $menu-height;
  & > * {
    min-height: calc(100vh - #{$menu-height});
    flex: 1 0 auto;
  }
}
