@mixin inline-list {
  white-space: nowrap;

  li {
    text-transform: lowercase;
    display: inline-block;
  }

  li + li {
    margin-left: -0.6em;
    &::before { content: ', '; }
  }
}

@mixin underline {
  border-bottom: 1px solid;
  text-decoration: none;
  margin-bottom: -1px;
  width: auto;
  display: inline;
}

@mixin menubar {
  white-space: nowrap;
  padding-top: $menu-padding-top;
  padding-bottom: $menu-padding-bottom;
  height: $menu-height;
  overflow: hidden;
}

@mixin hr ($position: bottom) {
  position: relative;
  &::after {
    content:'';
    position: absolute;
    #{$position}: 0;
    left: -$site-margin;
    right: -$site-margin;
    border-#{$position}: 1px solid $black;
  }
}

@mixin loader {
  @keyframes fade-pulse {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  position: relative;

  &::before {
    z-index: -1;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: $white;
    content: '\0025CA';
    font-size: 32px;

    animation: fade-pulse 1s linear infinite alternate;
  }
}
