.about {
  &__sections {
    @include row($site-margin);
    @include inline-list;
    @include menubar;
    @include invisible-x-scroll;

    background-color: $white;
    color: $black;
    border-bottom: 1px solid;

    position: sticky;
    top: $menu-height;
    z-index: 2;

    ul {
      @include col(16);
    }

    li:last-child {
      margin-right: $site-margin;
    }

    a:not(:hover):not(.is-active) {
      border: none;
    }
  }
}
