.project {
  &__header {
    @include grid($main-grid, 0, $site-margin);
    @include menubar;

    z-index: 1;
    top: $menu-height;
    position: sticky;

    @include devices(tablet) {
      & > *:nth-child(2) {
        visibility: hidden;
      }
      & > *:nth-child(3) {
        text-align: right;
      }
    }

    @include devices(mobile) {
      & > *:nth-child(1) {
        width: 100%;
      }
      & > *:nth-child(2),
      & > *:nth-child(3) {
        display: none;
      }
    }
  }

  &__body {
    @include row($site-margin);
    & > *:nth-child(1) { @include col(4); }
    & > *:nth-child(2) { @include col(7, 1); }
    & > *:nth-child(3) { @include col(3, 1); }

    overflow: hidden;
    padding-top: $gutter * 1.5;
    padding-bottom: $menu-height * 3;
    background-color: $white;
    color: $black;

    p + p {
      margin-top: $line-height;
    }

    @include devices(mobile, tablet) {
      @include grid((16, 16, 16), 0, $site-margin);

      &-column {
        padding-left: $site-margin !important;
        padding-right: $site-margin !important;
        margin-left: 0 !important;

        &:empty {
          display: none;
        }

        &:not(:last-child) {
          padding-bottom: $menu-height * 2;
        }

        &:not(:empty) + & {
          @include hr(top);
          padding-top: $menu-padding-top;
        }
      }
    }
  }
}
