.footer {
  @include grid((5, 2, 2, 4, 3), 0, $site-margin);
  @include menubar;

  white-space: unset;
  height: auto;

  z-index: 1;
  position: sticky;

  @include desktop {
    margin-bottom: -$footer-height--desktop;
    top: calc(100vh - #{$footer-height--desktop});
  }

  color: $white;
  background-color: $black;
  text-transform: lowercase;

  a:not(:hover) {
    border: none;
  }

  @include devices(mobile, tablet) {
    padding-top: $menu-padding-top * 1.5;

    &__column {
      width: 100% !important;

      &:not(:first-child) {
        margin-left: $site-margin - $gutter / 2;
      }

      &:not(.should-not-break) {
        margin-bottom: $line-height;
      }
    }

    div {
      width: 100%;
    }
  }
}

body[data-template='projects'] .footer {
  filter: invert(1);
}

body[data-template='project'] {
  @include desktop {
    .sticky-footer-wrapper {
      display: flex;
      flex-direction: column-reverse;
    }

    .project__footer {
      margin-top: -1px;
      margin-bottom: $footer-height--desktop;
    }
  }
}
