.menu {
  @include grid($main-grid, 0, $site-margin);
  @include menubar;

  z-index: 2;
  position: fixed;
  top: 0;
  display: flex;
  text-transform: lowercase;

  user-select: none;
  background-color: $black;

  a:not(:hover) {
    border: none;
  }

  @include devices(iphone5SE) {
    &__logo a {
      // NOTE: this is a hack to hide the content
      font-size: 0.1rem;
      letter-spacing: -0.1rem;

      border: 0;

      margin-left: -0.4rem;
      &::before {
        font-size: 2.4rem;
        vertical-align: -0.3rem;
        content: '\0025CA';
      }
    }
  }

  &__nav ul {
    display: flex;
    justify-content: space-between;

    @include devices(mobile, tablet) {
      justify-content: flex-end;
    }

    @include big-ass-desktop {
      justify-content: flex-start;
    }

    li {
      display: inline-block;
    }

    li + li {
      margin-left: $gutter * 2;
    }
  }

  &__subtitle[data-alternates]:not([data-alternates='']) span {
    cursor: help;
    display: inline-block;
  }

  @include mobile {
    &__subtitle {
      visibility: hidden;
    }
    &__nav {
      width: 100%;
    }
  }
}
