.about-section {
  position: relative;
  padding-top: $menu-padding-top;
  padding-bottom: $menu-height * 3;
  background-color: $white;
  color: $black;

  & + & {
    border-top: 1px solid;
  }

  @include desktop {
    &.is-merged-with-previous-section {
      border-top: none;
      padding-top: 0;
      margin-top: -$line-height;
    }
  }

  @include devices(mobile, tablet) {
    padding-left: $site-margin;
    padding-right: $site-margin;
    &__column + &__column {
      margin-top: 1em;
    }
  }

  &__anchor {
    position: absolute;
    top: -($menu-height * 2);
    visibility: hidden;

    @include devices(mobile, tablet) {
      top: -$menu-height;
    }

    @if $debug {
      visibility: visible;
      height: 0px;
      border-top: 2px dashed rgba(red, 0.3);
      &::after {
        content: '#'attr(id);
        color: rgba(red, 0.3);
      }
    }
  }

  h2, h3 {
    text-transform: lowercase;
    margin-bottom: $line-height;
  }

  p + p,
  p + ul {
    margin-top: $line-height;
  }

  // NOTE: this allows the last .about-section to be big enough
  // to trigger controllers/toc-highlighter on its anchor
  // When last-child is merged-with-previous-section, previous section height
  // should be taken in consideration, which is not possible in CSS:
  // SEE views/about.js for this implementation.
  &:last-child:not(.is-merged-with-previous-section) {
    min-height: calc(100vh - #{$menu-height * 2} - #{$footer-height--desktop});

    @include devices(mobile, tablet) {
      min-height: calc(100vh - #{$menu-height} - #{$footer-height--mobile});
    }
  }

  &__content {
    h3 {
      margin-top: $line-height;
      text-transform: unset;
    }

    h3 + ul {
      margin-top: -$line-height;
    }

    ul {
      margin-bottom: $line-height;
      margin-left: 0;
      padding-left: 2ch;
      text-indent: -2ch;
    }

    li::before {
      content: '–\00a0';
    }
  }

  @include devices(mobile, tablet) {
    padding-top: 0;
    margin-top: -$menu-height;

    &__column:first-child h1,
    &__column:first-child h2,
    &__column:first-child h3 {
      @include menubar;
      @include hr;
      padding-left: $site-margin;
      padding-right: $site-margin;
      margin-left: -$site-margin;
      margin-right: -$site-margin;
    }
  }

}
