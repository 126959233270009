.vimeo {
  @include loader;

  // NOTE: padding-top is applied inline based on vimeo's ratio, SEE snippets/vimeo.php
  padding: 0;

  line-height: 0;
  font-size: 0;
  overflow: hidden;

  &__link,
  &__cover,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  // NOTE: this disables the lazyloading fade-in animation on all lazyloaded
  // iframe, because the fade-in from cover to moving video is really ugly
  iframe[data-lozad][data-loaded="true"] {
    animation: none !important;
    opacity: 1;
  }

  @include mobile {
    &.has-user-request-to-play &__cover,
    &:not(.has-user-request-to-play) iframe {
      display: none;
    }

    &[data-fullscreen] &__cover::after {
      $vimeo__cover-button--size: 70px;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;

      background-image: url('/assets/images/play-button.svg');
      background-size: $vimeo__cover-button--size $vimeo__cover-button--size;
      width: $vimeo__cover-button--size;
      height: $vimeo__cover-button--size;

      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  iframe.no-gui {
    pointer-events: none;
  }
}
