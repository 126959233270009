@mixin debug ($color: red, $width: 1px) {
  outline: $width solid $color;
}

@mixin invisible-x-scroll {
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none;
  scrollbar-height: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    height: 0;
    background: transparent;
  }
}

@mixin easing ($duration: 100ms, $property: all) {
  @if type-of($property) == list {
    $transitions: '';
    @each $p in $property {
      $transitions: #{$p} #{$duration} cubic-bezier(0.250, 0.100, 0.250, 1.000), #{$transitions};
    }
    transition: $transitions;
  } @else {
    transition: $property $duration cubic-bezier(0.250, 0.100, 0.250, 1.000);
  }
}

@mixin progress-loader ($thickness: 1rem, $size: 50vw, $foreground: black, $background: transparent, $speed: 1s) {
 @keyframes loader-move {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(calc(100vw + 100%)); }
  }
  content: '';

  pointer-events: none;
  position: absolute;
  left: 0;
  width: $size;

  border-top: $thickness solid $foreground;
  animation: loader-move $speed linear infinite;
}

@mixin loader ($size: 1rem, $thickness: 2px, $foreground: transparent, $background: $white, $speed: 500ms) {
  @keyframes loader-spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }

  position: relative;

  &::before {
    content: '';
    z-index: -1;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;

    width: $size;
    height: $size;
    margin-left: -$size / 2;
    margin-top: -$size / 2;

    border: $thickness solid $background;
    border-top: $thickness solid $foreground;
    border-left: $thickness solid $foreground;
    border-radius: 50%;
    animation: loader-spin $speed linear infinite;
  }
}

@mixin checkerboard ($size: 48px, $color: #808080, $background: white) {
  background-color: $background;
  background-image: linear-gradient(45deg, $color 25%, transparent 25%), linear-gradient(-45deg, $color 25%, transparent 25%), linear-gradient(45deg, transparent 75%, $color 75%), linear-gradient(-45deg, transparent 75%, $color 75%);
  background-position: 0 0, 0 #{$size / 2}, #{$size / 2} -#{$size / 2}, -#{$size / 2} 0px;
  background-size: $size $size;
}
