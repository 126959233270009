$fullscreen__nav--width: 5rem;
$fullscreen__nav--vertical-optical-adjustment: 9px;
$fullscreen__nav--vertical-optical-adjustment--mobile: 3px;

@keyframes slide-from-left {
  from { transform: translateX(-100%); }
  to { transform: none; }
}

@keyframes slide-from-right {
  from { transform: translateX(100%); }
  to { transform: none; }
}

*[data-fullscreen],
*[data-fullscreen] * {
  cursor: pointer;
  cursor: zoom-in;
}

.fullscreen {
  @include loader;

  cursor: pointer;
  cursor: zoom-out;

  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($black, 1);

  &__caption {
    @include menubar;
    @include invisible-x-scroll;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    padding-left: $site-margin;
    padding-right: $site-margin;
    background-color: $black;


    @include mobile (portrait) {
      left: $fullscreen__nav--width;
      right: $fullscreen__nav--width;
    }

    &:empty { display: none; }
  }

  &__media {
    user-select: none;
    cursor: pointer;
    cursor: zoom-out;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    display: grid;
    height: 100%;
    width: calc(100vw - #{$fullscreen__nav--width * 2});
    @include mobile (portrait) {
      width: 100vw;
      margin-top: -$menu-height / 2;
      max-height: calc(100vh - #{$menu-height});
    }
  }

  &.is-vimeo &__caption:not(:empty) + &__media {
    margin-top: -$menu-height / 2;
    max-height: calc(100vh - #{$menu-height});
  }

  &__media img {
    @if $debug { @include checkerboard(24px); }
    @else { background-color: $black; }

    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  &__media iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  &__media > * {
    opacity: 0;
  }

  &__media > *[data-loaded="true"] {
    @include easing(1s, opacity);
    opacity: 1;
  }

  &[data-transition="from-left"] &__media > * {
    animation: slide-from-left 100ms ease-out forwards;
  }

  &[data-transition="from-right"] &__media > * {
    animation: slide-from-right 100ms ease-out forwards;
  }

  // NOTE: this pseudo-element allows capturing click event on iframe,
  // without interfering with Vimeo's toolbar
  &.is-vimeo &__media::after {
    $vimeo__progressbar--height: 7.5rem;
    $vimeo__share--width: 5rem;
    $vimeo__ratio: 16/9;

    content: '';

    position: absolute;
    left: 0;
    right: $vimeo__share--width;

    top: 0;
    bottom: calc(50% - (56.25vw - #{$fullscreen__nav--width}) / 2 + #{$vimeo__progressbar--height});

    @media (min-aspect-ratio: 16/9) {
      bottom: $vimeo__progressbar--height;
      right: calc(50% - 82.22vh + #{$vimeo__share--width});
    }
  }

  &__btn {
    @include reset-input;
    position: fixed;

    font-size: 4rem;
    text-align: center;

    cursor: pointer;
    color: $white;
  }

  &__btn--left,
  &__btn--right {
    top: 0;
    height: calc(100vh + #{$fullscreen__nav--vertical-optical-adjustment});
    width: $fullscreen__nav--width;

    @include mobile (portrait) {
      z-index: 3;
      bottom: $fullscreen__nav--vertical-optical-adjustment--mobile;
      top: unset;
      height: auto;
    }
  }

  &__btn--left { left: 0; }
  &__btn--right { right: 0; }
}
