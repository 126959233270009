@import 'sass/reset';
@import 'sass/fonts';
@import 'sass/helpers';
@import 'sass/devices';

$debug: false;
$display-grid: false;
$display-grid--opacity: 0.1;

$black: #000000;
$white: #FFFFFF;

$font-size: 1.6rem;
$line-height: 2.1rem;

$main-grid: (5, 8, 3);
$grid-length: 16;
$gutter: 1.2rem;
$site-margin: $gutter;

$menu-height: 3.6rem;
$menu-padding-top: 0.9rem;
$menu-padding-bottom: 0.9rem;

$footer-height--desktop: 5.9rem;
$footer-height--mobile: 29.5rem;

// NOTE: this allows getting sass variable in js using controllers/sass-variables
:root {
  --site-margin: #{$site-margin};
}

@import 'sass/mixins';
@import 'sass/base';
@import 'sass/grid';

@import 'components/about';
@import 'components/about-section';
@import 'components/about-section--basic';
@import 'components/about-section--grid';
@import 'components/about-section--list';
@import 'components/default';
@import 'components/footer';
@import 'components/fullscreen';
@import 'components/menu';
@import 'components/project';
@import 'components/project-categories';
@import 'components/project-date';
@import 'components/project-gallery';
@import 'components/project-links';
@import 'components/project-metas';
@import 'components/project-preview';
@import 'components/project-related';
@import 'components/projects';
@import 'components/projects-categories';
@import 'components/sitemap';
@import 'components/vimeo';
