.projects {
  &__header {
    @include grid($main-grid, 0, $site-margin);
    @include menubar;

    position: sticky;
    top: calc(#{$menu-height});

    color: $black;
    background-color: $white;
    z-index: 2;

    & > * {
      width: 100% !important;
    }
  }

  &__items {
    width: 100%;
    font-size: 0;
    padding-top: $site-margin;
    padding-left: ($site-margin - $gutter / 2);
    padding-right: ($site-margin - $gutter / 2);
  }

  .project-preview {
    @include col(4);

    &:first-child { padding-left: $gutter / 2 !important; }
    &:last-child { padding-right: $gutter / 2 !important; }

    margin-bottom: $gutter + $line-height;

    @include tablet { @include col(8); }
    @include mobile { @include col(8); }

    display: inline-flex !important;
    flex-direction: column-reverse !important;

    &:hover h3 {
      @include underline;
    }

    &__header {
      top: unset;
      position: relative;
      display: block !important;
      background-color: transparent !important;
      color: $white !important;
      padding: $menu-padding-top 0 0 0;
      * { padding-left: 0 !important; }
      height: auto;
      white-space: nowrap;
      @include mobile { white-space: normal; }

      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .project__header-column {
    padding: 0;
    display: block;
    margin-bottom: 0.4rem;
  }
}
