.project-gallery {
  user-select: none;

  a, figure, img, iframe {
    font-size: 0;
    display: block;
    box-sizing: border-box;
    border: 0;
    width: 100%;
    height: auto;
  }

  li {
    font-size: 0;
    width: 100%;
  }

  @if $debug {
    img, iframe {
      @include debug(white);
    }
  }

  // NOTE: This opt-in class allows the user to force the height of all images
  // to be the same. This is used when a user want to display several images
  // side by side with the same ratio: because of the way the grid handles outer
  // gutters (thanks Patrick !), the width of the far left and far right images
  // are always greater than the inside images (by one gutter).
  // The solution is to artificially increase by one gutter the width of the
  // inside image, then crop to the correct container width.
  &__row.should-match-height {
    @include desktop {
      display: flex;

      a, figure, img {
        height: 100%;
        overflow: hidden;
      }

      li:not(:last-child) figure,
      li:not(:first-child) figure {
        width: calc(100% + #{$gutter});
      }
    }
  }

  &__row {
    & + & {
      margin-top: $gutter;
    }

    @include desktop {
      &[data-ratio='████████████████ (16)'] { @include grid((16)); }
      &[data-ratio='█████████████░░░ (13-)'] { @include grid((13)); }
      &[data-ratio='░░░█████████████ (-13)'] { @include grid((13), 3); }
      &[data-ratio='███████████░░░░░ (11-)'] { @include grid((11)); }
      &[data-ratio='░░░░░███████████ (-11)'] { @include grid((11), 5); }
      &[data-ratio='████████░░░░░░░░ (8-)'] { @include grid((8)); }
      &[data-ratio='░░░░░░░░████████ (-8)'] { @include grid((8), 8); }
      &[data-ratio='█████░░░░░░░░░░░ (5-)'] { @include grid((5)); }
      &[data-ratio='░░░░░░░░░░░█████ (-5)'] { @include grid((5), 11); }
      &[data-ratio='███████████▓▓▓▓▓ (11-5)'] { @include grid((11, 5)); }
      &[data-ratio='█████▓▓▓▓▓▓▓▓▓▓▓ (5-11)'] { @include grid((5, 11)); }
      &[data-ratio='████████▓▓▓▓▓▓▓▓ (8-8)'] { @include grid((8, 8)); }
      &[data-ratio='████████▓▓▓▓▓░░░ (8-5-)'] { @include grid((8, 5)); }
      &[data-ratio='█████▓▓▓▓▓▓▓▓░░░ (5-8-)'] { @include grid((5, 8)); }
      &[data-ratio='░░░████████▓▓▓▓▓ (-8-5)'] { @include grid((8, 5), 3); }
      &[data-ratio='░░░█████▓▓▓▓▓▓▓▓ (-5-8)'] { @include grid((5, 8), 3); }
      &[data-ratio='█████▓▓▓▓▓░░░░░░ (5-5-)'] { @include grid((5, 5)); }
      &[data-ratio='░░░░░░█████▓▓▓▓▓ (-5-5)'] { @include grid((5, 5), 6); }
      &[data-ratio='█████▓▓▓▓▓█████░ (5-5-5-)'] { @include grid((5, 5, 5)); }
      &[data-ratio='░█████▓▓▓▓▓█████ (-5-5-5)'] { @include grid((5, 5, 5), 1); }
    }

    @include devices(mobile, tablet) {
      @include grid((16, 16, 16));
      li {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      li + li { margin-top: $gutter; }
      .grid__padding-right--hack { display: none; }
    }
  }
}
