.project-preview {
  width: 100%;

  a {
    display: block;
    border: none;
    font-size: 0;
  }

  &__header {
    @include grid($main-grid, 0, $site-margin);
    @include menubar;

    z-index: 1;
    position: sticky;
    top: $menu-height;

    background-color: $black;
    color: $white;

    @include tablet {
      & > *:nth-child(2) {
        display: none;
      }

      & > *:nth-child(3) {
        float: right;
        text-align: right;
      }
    }

    @include mobile {
      & > *:first-child {
        width: 100%;
        display: block;
      }
      & > *:not(:first-child) {
        display: none;
      }
    }
  }

  &__cover {
    user-select: none;
    font-size: 0;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
