.project-metas {
  &__meta:not(:last-child) {
    margin-bottom: $line-height;
  }

  &__meta-label {
    display: block;
    text-transform: lowercase;
  }

  &__meta-label::after {
    content: ':';
  }
}
