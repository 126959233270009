.project-related {
  display: flex;

  @include mobile {
    display: block;
  }

  .project-preview {
    width: 50%;

    @include mobile {
      min-width: 100%;
    }

    .project__header-column:not(:first-child) {
      display: none;
    }

    &:hover h3 {
      @include underline;
    }
  }
}
